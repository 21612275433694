html{
    scroll-behavior: smooth;
}
body{
    position: relative;
    overflow-x: hidden;
}
h1,h2,h3,h4,h5,h6{
    font-family: "Adman_X_Bd";
}
p{
    font-family: "Adman_X_Li";
    font-size: 22px;
    line-height: 24px;
}
.color-primary{
    color:#a55056;
}
.gold{
    color:#d4b790;
}
.lh-28{
    line-height: 34px;
}
a{
    text-decoration: none !important;
}
.cursor-pointer{
    cursor: pointer;
}
.bannerModal{
    .modal-body{
        padding: 0;
    }
    .modal-content{
        border: none;
    }
    .btn-close{
        position: absolute;
    right: -12px;
    top: -12px;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 6px;
    }
}
.phone{
    a{
        color: #fff;
    }
    a:hover{
        color: #a55056;
    }
}
.w-30{
    width: 25%;
}
a.red{
    color: #a55056 !important;
}
.container-navbar{
    max-width:100%;
    
    position: sticky;
    top:0;
    background-color: #ffffff;
    z-index: 999;
    .navbar {
        max-width: 90%;
        margin:20px auto;
    }
    .navbar-nav{
        
        li{
            padding: 0px 20px 0px;
            position: relative;
            margin: 0px 6px;
            a.nav-link{
                font-family: "Adman_X_Li";
                color:#000000;
                font-size: 22px;
                padding: 0.1rem 1rem;
            }
        }
        // li::after{
        //     width: 4px;
        //     height: 4px;
        //     position: absolute;
        //     right: 0px;
        //     background-color: #000000;
        //     border-radius: 2px;
        //     content: "";
        //     top: 16px;
        // }
       li:last-child:after{
           display: none;
        }
    //     li:first-child a{
    //         color: #a55056;
    //         font-weight: bold;
    //         padding-left: 24px;
    //         padding-right: 24px;
    //         border-radius: 8px;
    //         background: rgba(213,184,144,1);
    //         background: -moz-linear-gradient(top, rgba(213,184,144,1) 0%, rgba(213,184,144,1) 49%, rgba(230,206,177,1) 100%);
    //         background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(213,184,144,1)), color-stop(49%, rgba(213,184,144,1)), color-stop(100%, rgba(230,206,177,1)));
    //         background: -webkit-linear-gradient(top, rgba(213,184,144,1) 0%, rgba(213,184,144,1) 49%, rgba(230,206,177,1) 100%);
    //         background: -o-linear-gradient(top, rgba(213,184,144,1) 0%, rgba(213,184,144,1) 49%, rgba(230,206,177,1) 100%);
    //         background: -ms-linear-gradient(top, rgba(213,184,144,1) 0%, rgba(213,184,144,1) 49%, rgba(230,206,177,1) 100%);
    //         background: linear-gradient(to bottom, rgba(213,184,144,1) 0%, rgba(213,184,144,1) 49%, rgba(230,206,177,1) 100%);
    //         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5b890', endColorstr='#e6ceb1', GradientType=0 );
    //     }
    }
    
}
.sale-icon{
    cursor: pointer;
    label{
        font-family: "Adman_X_Li";
        color: #000000;
        text-align: center;
        padding-right: 54px;
        line-height: 16px;
        font-size: 20px;
        margin-top:8px;
        display: none;
        text-decoration: none;
        span{
            color: #a55056;
            font-family: "Adman_X_Bd";
        }
    }
    img{
        position: relative;
        right:2px;
        top:0px;
    }
}
.sale-icon:hover{
    background-color: #d4b790;
    width: 170px;
    height: 60px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    a{
        text-decoration: none;
    }
    label{
        display: block;
        text-decoration: none;
    }
    img{
        position: absolute;
        right:2px;
        top:4px;
    }
}
.logo-penguinasset{
    max-width: 240px;
}
.slick-next{
    right: 0;
}
.slick-dots{
    bottom: 10px !important;
}
.search-block{
    background-color: #a65055;
    width:100%;
    padding: 20px;
    border-radius: 16px;
    margin: 20px auto;
    font-family: "Adman_X_Li";
    a{
        text-decoration: none;
        color: #ffffff;
    }
    a:hover{
        color: #000000;
    }
    .text-search{
        border-radius: 8px;
        border: 2px solid #d4d3d3;
        background-image: linear-gradient(to top, #ffffff, #f4f4f4, #e9e9e9, #dfdede, #d4d3d3);
        width: calc(100% - 100px);
        height: 44px;
        margin: 12px auto;
        padding: 4px 24px;
        font-size: 22px;
    }
    .btn-search{
        position: absolute;
        right: 100px;
        top:10px;
        max-width: 50px;
    }
    .search-full{
        position: relative;
        padding-right: 80px;
    }
}
.ddl-penguin{
    #dropdown-item-button{
        background-image: linear-gradient(to top, #ffffff, #f4f4f4, #e9e9e9, #dfdede, #d4d3d3);
        color: #9e9d9d;
        width: 100%;
        text-align: left;
        font-family: "Adman_X_Li";
        font-size: 20px;
        position: relative;
        background-color: transparent;
        border: 2px solid #d4d3d3;
        border-radius: 12px;
        padding: 0.2rem 0.75rem;
    }
    .dropdown-toggle::after{
        position: absolute;
        right: 12px;
        top:16px;
    }
    .dropdown-toggle::before{
        position: absolute;
        right: 0px;
        top:0px;
        background-image: linear-gradient(to bottom, #000000, #161616, #242424, #343434, #444444);
        width: 36px;
        height: 100%;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        content: "";
    }
    .dropdown-menu{
        width: 100%;
        background-image: linear-gradient(to top, #ffffff, #f4f4f4, #e9e9e9, #dfdede, #d4d3d3);
    }
    
}
.col-20{
    width: 20%;
}
.btn-penguin{
    background: rgba(165,80,86,1);
    background: -moz-linear-gradient(top, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(165,80,86,1)), color-stop(52%, rgba(165,80,86,1)), color-stop(72%, rgba(184,115,119,1)), color-stop(100%, rgba(184,115,119,1)));
    background: -webkit-linear-gradient(top, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    background: -o-linear-gradient(top, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    background: -ms-linear-gradient(top, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    background: linear-gradient(to bottom, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a55056', endColorstr='#b87377', GradientType=0 );
    border: none !important;
    background-color: transparent;
    border-radius: 8px!important;
    padding:.375rem 3rem !important;
    font-family: "Adman_X_Li"!important;
    font-size: 20px!important;
}
.btn-penguin:hover{
    color: white!important;
}
.btn-black{
    background-color: #231f20;
    border: none !important;
    border-radius: 8px!important;
    padding: 0.5rem 1rem !important;
    font-family: "Adman_X_Li";
    font-size: 20px!important;
    display: inline-block;
    color: #ffffff;
    line-height: 22px;
    margin: 0px 30px;
}
.width-260{
    width: 260px;
}
.btn-black:hover, .btn-black-active{
    background: rgba(165,80,86,1);
    background: -moz-linear-gradient(top, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(165,80,86,1)), color-stop(52%, rgba(165,80,86,1)), color-stop(72%, rgba(184,115,119,1)), color-stop(100%, rgba(184,115,119,1)));
    background: -webkit-linear-gradient(top, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    background: -o-linear-gradient(top, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    background: -ms-linear-gradient(top, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    background: linear-gradient(to bottom, rgba(165,80,86,1) 0%, rgba(165,80,86,1) 52%, rgba(184,115,119,1) 72%, rgba(184,115,119,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a55056', endColorstr='#b87377', GradientType=0 );
    border: none !important;
    background-color: transparent;
    border-radius: 8px!important;
    padding:0.5rem 1rem !important;
    font-family: "Adman_X_Li";
    font-size: 20px!important;
    color: #ffffff;
}
.badge-1{
    background-image: url("../../public/green.png");
    color: #ffffff;
}
.badge-2{
    background-image: url("../../public/skyblue.png");
    color: #ffffff;
}
.badge-5{
    background-image: url("../../public/red.png");
    color: #ffffff;
}
.badge-9{
    background-image: url("../../public/red.png");
    color: #ffffff;
}
.badge-8{
    background-image: url("../../public/yellow.png");
    color: #ffffff;
}
.badge-3{
    background-image: url("../../public/blue.png");
    color: #ffffff;
}
.property-item{
    font-family: "Adman_X_Li" !important;
    color:#818594;
    position: relative;
    
    .badge{
        background-size:cover ;
        font-size: 12px;
        position: absolute;
        width: 120px;
        height: 99px;
        bottom:0px;
        right:0px;
        padding: 6px 0px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 16px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        label{
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 100%;
            text-align: right;
            font-size: 16px;
            
        }
    }
    .item-img{
        position: relative;
        img{
            min-height: 220px;
            max-height: 220px;
            object-fit: cover;
        }
        
    }
    .item-title{
        color:#a55056 !important;
        font-size: 22px;
        font-family: "Adman_X_Bd" !important;
        text-decoration: none!important;
        a{
            color:#a55056 !important;
            text-decoration: none!important;
        }
    }
    .item-type{
        color:#818594;
        font-size: 16px;
        font-family: "Adman_X_Li" !important;
    }
    .item-location{
        color:#818594;
        font-size: 16px;
        font-family: "Adman_X_Li" !important;
    }
    .item-desc{
        border-bottom: 2px solid #e1e2e2;
    }
    .addon{
        label{
            display: block;
            font-size: 16px;
            color: #000;
        }
    }
    .item-action{
        color:#000000;
        .price{
            color:#000000;
            font-size: 20px;
            font-weight: bold;
        }
        img{
            width: 20px;
            margin: 0px 4px;
        }
        .action{
            padding-top: 2px;
        }
    }
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 0.3rem;
}
footer{
    background-color: #000000;
    padding: 40px 6%;
    h4{
        color: #ffffff;
        font-family: "Adman_X_Bd" !important;
        margin: 12px 0;
        letter-spacing: 2px;
    }
    .f-blog{
        text-align: center;
    }
    .logo-footer{
        max-width: 160px;
    }
    .addr,.phone,.mail{
        padding-left: 32px;
        position: relative;
        img{
            position: absolute;
            top:6px;
            left:0;
        }
    }
    p{
        color:#ffffff;
        font-size: 18px;
        font-family: "Adman_X_Li" !important;
    }
    .f-menu{
        a{
            color:#ffffff;
            text-decoration: none;
            font-family: "Adman_X_Li" !important;
            font-size: 22px;
        }
        a:hover{
            color: #a55056;
        }
        ul{
            margin-left: 0;
            padding: 0;
            list-style: none;
            li{
                a{
                    font-size: 16px;
                }
            }
        }
    }
    
}
.collapse:not(.show) {
    display: none !important;
}
.followus{
    position: absolute;
    right: 0;
    bottom: 4px;
    background-color: #a55056;
    list-style: none;
    margin-left: 0;
    width: 52px;
    padding: 20px 16px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    li{
        margin: 14px 0;
        text-align: center;
        img{
            max-width: 20px;
        }
    }
    li:first-child{
        height: 62px;
        position: relative;
        div{
            transform: rotate(-90deg);
            width: 80px;
            position: absolute;
            left: -35px;
            top: 14px;
            color: #ffffff;
        }
    }
}
.contact-widget{
    position: absolute;
    right: -6px;
    bottom: 340px;
}
.asset-group-seleted{
    label{
        color: #a55056;
        font-family: "Adman_X_Li" !important;
        font-size: 24px;
    }
}
.asset-group{
    text-align: center;
    a{
        margin: 0px 24px;
        text-decoration: none;
    }
    label{
        display: block;
        color: #000000;
        font-family: "Adman_X_Li" !important;
        font-size: 16px;
        line-height: 18px;
        margin-top: 2px;
    }
}
.property-list{
    .row:first-child{
        margin-left: -20px;
        margin-right: -20px;
    }
}
    
.property-list-item:last-child{
    .sepetor{
        display: none;
    }
}
.property-list-item{
    font-family: "Adman_X_Li" !important;
    color:#818594;
    position: relative;
    
    padding-bottom: 36px;
    .badge{
        font-size: 12px;
        position: absolute;
        background-size: cover;
        width: 120px;
        height: 99px;
        bottom:0px;
        right:0px;
        padding: 6px 0px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 16px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        label{
            position: absolute;
            bottom: 10px;
            right: 8px;
            width: 100%;
            text-align: right;
            font-size: 16px;
        }
        
    }
    .promotion{
        left:-13px;
    }
    .item-img{
        position: relative;
        img{
            min-height: 220px;
            max-height: 220px;
            object-fit: cover;
        }
    }
    .item-title{
        color:#a55056;
        font-size: 22px;
        font-family: "Adman_X_Bd" !important;
        a{
            color:#a55056;
            text-decoration: none;
        }
    }
    .item-type{
        color:#000000;
        font-size: 20px;
        line-height: 20px;
        font-family: "Adman_X_Li" !important;
    }
    .item-location{
        color:#000000;
        font-size: 20px;
        line-height: 22px;
        font-family: "Adman_X_Li" !important;
    }
    .item-short{
        font-size: 20px;
        font-family: "Adman_X_Li" !important;
        line-height: 22px;
    }
    .addon{
        margin: 32px 0 54px;
        label{
            display: block;
            font-size: 16px;
        }
    }
    .price{
        color:#ffffff;
        font-size: 20px;
        font-weight: bold;
        background-color: #000000;
        border-radius: 12px;
        padding: 6px;
    }
    .item-action{
        color:#000000;
       
        img{
            width: 20px;
            margin: 0px 4px;
        }
        .action{
            padding-top: 2px;
        }
    }
    .item-code,.item-post-date{
        color: #d4b790;
        font-size: 20px;
        line-height: 22px;
    }
    
}
.promotion{
    max-width: 100px;
    position: absolute;
    top:8px;
    left: -13px;
    min-height: auto !important;
}
.border-rounded{
    border-radius: 15px;
}
.sepetor{
    height: 2px;
    background-color:  #231f20;
}
.page-summary{
    font-family: "Adman_X_Li" !important;
    font-size: 20px;
}
.pagination {
    margin-top: 32px;
    .page-link{
        border: none ;
        font-family: "Adman_X_Li" !important;
        color: #818594;
        font-size: 24px;
    }
    .page-link:hover {
        z-index: 2;
        color: #000000;
        background-color: transparent;
        border-color: transparent;
    }
    img{
        max-width: 30px;
    }
}
.carousel .thumbs{
    padding-left: 0;
}
.breadcrumb{
    max-width: 90%;
    margin: auto;
    
    ul{
        list-style: none;
        margin-left:0;
        padding-left: 0;
        li{
            font-family: "Adman_X_Li" !important;
            color: #818594;
            display: inline-block;
            margin-right: 24px;
            position: relative;
        }
        li::after{
            content: ">";
            position: absolute;
            right: -16px;
        }
        li:last-child:after{
            content: "";
            position: absolute;
            right: -16px;
        }
    }
    
}
.asset-header{
    max-width: 90%;
    margin: auto;
    h1{
        color:#a55056;
        font-size: 28px;
        font-family: "Adman_X_Bd" !important;
    }
    p{
        font-family: "Adman_X_Li" !important;
            color: #000;
            font-size: 22px;
    }
}
.thumbs-wrapper {
    margin: 20px auto!important;
    overflow: hidden;
    max-width: 1320px!important;
}
.control-next{
    width:37px;
    height: 37px;
    top:50%!important;
    right:40px!important;
    transform: translateY(-50%);
    background-image: url('../../public/icon/next.png') !important;;
}
.control-prev{
    width:37px;
    height: 37px;
    top:50%!important;
    left:40px!important;
    transform: translateY(-50%);
    background-image: url('../../public/icon/prev.png') !important;;
}
.carousel .control-next.control-arrow:before,.carousel .control-prev.control-arrow:before{
    display: none!important;
}
.carousel.carousel-slider .control-arrow:hover{
    background-color: transparent!important;
}
.thumbs-wrapper{
    .control-next{
        display: none !important;
    } 
    .control-prev{
        display: none !important;
    } 
}
.asset-view{
    font-family: "Adman_X_Li" !important;
    font-size: 20px;
    a{
        margin-left:6px;
    }
}
.asset-detail{
    font-family: "Adman_X_Li" !important;
    font-size: 20px;
    .asset-name{
        font-weight: bold;
        font-size: 24px;
    }
    .asset-desc{
        font-weight: bold;
        font-size: 24px;
    }
    .asset-tags{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            background-color: #4c4c4c;
            color: #ffffff;
            margin: 0px 6px;
            padding: 2px 8px;
            font-size: 14px;
            border-radius: 2px;
            display: inline-block;
        }
    }
}
.asset-attribute{
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    width: 100%;
    padding: 24px;
    border-radius: 24px;
    .attr{
        text-align: center;
        margin-bottom: 24px;
      label{
            display: block;
            line-height: 22px;
            font-size: 18px;
            span{
                font-size: 14px;
                display: block;
            }
        }
        .a-title{
           color:#000000;
           margin-top:12px;
        }
        .a-value{
            color:#a55056;
         }
    }
    .facilities{
        background-color: #a55056;
        color: #ffffff;
        text-align: center;
        font-size: 20px;
        width: 100%;
        border-radius: 8px;
        padding: 2px 16px;
    }
    
}
.relateItem{
    font-family: "Adman_X_Li" !important;
    font-weight: bold;
    background-color: #d4b790;
    color: #000000;
    text-align: center;
    font-size: 22px;
    border-radius: 8px;
    padding: 2px 24px;
    width: auto;
    display: inline-block;
}
.asset-contact{
    background-color: #000;
    border-radius: 8px;
    padding: 6px 24px;
    color: #fff;
    font-family: "Adman_X_Li" !important;
    font-weight: bold;
    font-size: 24px;
    position: relative;
    margin: 0px 0px 32px 0;
    a{
        text-decoration: none;
        color: #fff;
        float: right;
        img{
            margin-right: 8px;
        }
    }
}
.float-right{
    float: right;
}
.penguinInput{
    background-color: rgba(201,201,201,1);
    background: -moz-linear-gradient(top, rgba(201,201,201,1) 0%, rgba(240,240,240,1) 16%, rgba(240,240,240,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(201,201,201,1)), color-stop(16%, rgba(240,240,240,1)), color-stop(100%, rgba(240,240,240,1)));
    background: -webkit-linear-gradient(top, rgba(201,201,201,1) 0%, rgba(240,240,240,1) 16%, rgba(240,240,240,1) 100%);
    background: -o-linear-gradient(top, rgba(201,201,201,1) 0%, rgba(240,240,240,1) 16%, rgba(240,240,240,1) 100%);
    background: -ms-linear-gradient(top, rgba(201,201,201,1) 0%, rgba(240,240,240,1) 16%, rgba(240,240,240,1) 100%);
    background: linear-gradient(to bottom, rgba(201,201,201,1) 0%, rgba(240,240,240,1) 16%, rgba(240,240,240,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c9c9c9', endColorstr='#f0f0f0', GradientType=0 );
    border: none !important;
}
.penguinSelect{
    -webkit-appearance: menulist!important;
    -moz-appearance: menulist!important; 
    appearance: menulist!important; 
}

.contact-pannel{
    padding-left: 100px !important;
    font-family: "Adman_X_Li" !important;
}
.work-text{
    height: 120px;
}
.work-policy{
    
    padding: 0px 44px;
    p{
        font-size: 16px;
    }
}
.policy-text{
    font-size: 14px;
    line-height: 14px;
}
.sepete{
    width: 3px;
    height: 28px;
    background-color: #000;
    position: relative;
    top:9px;
    margin: 0px 20px;
}
.account{
    a{
        font-family: "Adman_X_Li" !important;
        text-decoration: none;
        color: #000;
        margin-left: 16px !important;
        padding: 0.1rem 1rem !important;
        font-size: 22px !important;
       
    }
    a:last-child{
        font-family: "Adman_X_Li" !important;
        text-decoration: none;
        color: #ffffff;
        margin-left: 8px;
        padding: 0.1rem 1rem !important;
    }
}
.our-works{
    background-color: #a55056;
    padding: 44px auto;
}
.ourworksType{
    text-align: center;
    label{
        display: block;
        font-family: "Adman_X_Li" !important;
        color: #fff;
        margin-top: 16px;
    }
}

.blog-item{
    font-family: "Adman_X_Li" !important;
   
    .blog-title{
        color: #a55056;
        font-size: 22px;
        margin: 12px 0 8px;
        a{
            color: #a55056;
        }
    }
    .blog-short{
        color: #000000;
        font-size: 20px;
        line-height: 24px;
    }
    .blog-read{
        text-align: right;
        margin: 12px 0;
        a{
            color: #a55056;
            font-size: 20px;
            text-decoration: none;
            
        }
    }
}
.contact-form{
    font-family: "Adman_X_Li" !important;
    font-size: 20px;
}
.contactus{
    max-width: 1024px;
    margin: auto;
}
.contactus-form{
    .row{
        .col-12:first-child{
            padding-right: 40px;
        }
        .col-12:last-child{
            padding-left: 40px;
        }
    }
}
.h-100vh{
    min-height: calc(100vh - 480px);
}
.addr,.phone,.mail{
    padding-left: 32px;
    position: relative;
    img{
        position: absolute;
        top:4px;
        left:0;
    }
}
.mail{
    position: relative;
    a.c-facebook{
        position: absolute;
        right: 166px;
        top: -23px;
    }
    a.c-phone{
        position: absolute;
        right: 106px;
        top: -23px;
    }
    a.c-line{
        position: absolute;
        right: 46px;
        top: -23px;
    }
}
iframe{
    max-width: 100% !important;
    max-height: 320px;
}
.filter-block{
    .col{
        width: 20%;
        flex: unset;
        margin-bottom: 18px;
    }
}
div.mrb-0{
    margin-bottom: 0px !important;
}
.navbar-light .navbar-toggler{
    color: #a55056 !important;
    border-color: rgba(166,80,86,.5)!important;
    position: relative;
    top:-10px
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #a55056 !important;
}
.a-active{
    color: #a55056 !important;
}
.modal-dialog{
    font-family: "Adman_X_Li";
}
.modal-title{
    color: #a55056;
}
.dropdown-item{
    font-family: "Adman_X_Li";
    font-size: 20px; 
}
.price-range{
    width: 40% !important;
}
.MuiSlider-root{
    color: #d4b790!important;
}
.MuiSlider-rail{
    height: 10px !important;
}
.MuiSlider-thumb{
    background-color: #a55056!important;
    border:6px solid #d4d3d3;
}
.boxSlider{
    position: relative;
    padding: 0px 54px;
    margin-top: 16px;
}
.lb_start,.lb_end{
    position: absolute;
    font-family: "Adman_X_Li";
    color:#9e9d9d ;
    font-size: 20px;
}
.lb_start{
    left:0;
}
.lb_end{
    right: 0;
}
.login-box{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding:48px 24px;
    border-radius: 16px;
    font-family: "Adman_X_Li";
    font-size: 20px;
    label{
        font-size: 20px;
    }
    a{
        color: #a55056;
    }
}
.pagination{
    align-items: center;
    justify-content: center;
}
.dropdown-menu{
    max-height: 300px;
    overflow-y: scroll;
}
.slick-next{
    right: 30px!important;
}
.slick-prev {
    left: 30px !important;
    z-index: 99;
}
.slick-prev, .slick-next{
    width: 36px!important;
    height: 36px!important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
}
.slick-prev:before, .slick-next:before{
    font-size: 40px !important;
    position: relative;
    left: -2px;
    top: 1px;
}
.blogdetail{
    max-width: 960px !important;
}
.page-item .page-link{
    outline: none;
}
.page-item:first-child .page-link{
    background-image: url('../../public/icon/prev.png');
    width: 30px;
    height: 30px;
    background-size: contain;
    margin-right: 16px;
    cursor: pointer;
}
.page-item.active .page-link{
    background-color: transparent !important;
    color: #000 !important;
}
.page-link:focus {
    z-index: 3;
    color: #000!important;
    background-color: transparent!important;
    outline: 0!important;
    box-shadow: none!important;
}
.page-item:last-child .page-link{
    background-image: url('../../public/icon/next.png');
    width: 30px;
    height: 30px;
    background-size: contain;
    margin-left: 16px  !important;
    cursor: pointer;
}
.page-item:last-child .page-link:hover, .page-item:first-child .page-link:hover{
    opacity: 0.8;
}
.profile{
    font-family: "Adman_X_Li";
    color:#000000;
    font-size: 22px;
    line-height: 26px;
    a{
        padding: 0px !important;
        margin: 0px !important;
        line-height: 22px;
    }
    a:last-child{
        color:#a55056 !important;
        padding: 0px !important;
        margin: 0px !important;
        line-height: 22px;
    }
}
.none-data{
    width: 100%;
    min-height: 450px;
    text-align: center;
    font-family: "Adman_X_Li";
    color: #a55056;
    padding: 100px;
    font-size: 24px;
}
.carousel .carousel-status{
    display: none;
}
.slider-wrapper{
    max-width: 603px;
    margin: auto !important;
}
.penguin-9{
    max-width: 110px;
    right: 0px;
    bottom:-30px;
    position: absolute;
}
.penguin-20{
    max-width: 110px;
    left: -60px;
    bottom:-20px;
    position: absolute;
}
.penguin-22{
    max-width: 110px   ;
    left: 20px;
    top:12px;
    position: absolute;
}
.penguin-10 {
    max-width: 60px;
    left: 310px;
    top: -40px;
    position: absolute;
}
.penguin-23 {
    max-width: 60px;
    top: -10px;
    position: absolute;
    left: -60px;
    display: inline-block;
}
.penguin-24 {
    max-width: 60px;
    right: -68px;
    top: -8px;
    position: absolute;
}
.penguin-8{
    max-width: 60px;
    position: absolute;
    top:-10px;
}
.button-contact{
    padding-top: 50px;
}
.p-relative{
    position: relative;
}
.d-inline-block{
    display: inline-block;
}
.red-color{
    color: #a55056;
}
.menu-asset{
   
    .submenu{
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        font-family: "Adman_X_Li";
        position: absolute;
        background-color: #eaedf3;
        left: 0;
        width:100%;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        display: none;
        z-index: 99;
        li{
            text-align: left;
            padding: 4px 20px;
            margin: 0;
            a{
                color: #818594;
                font-size: 18px;
            }
        }
        li::after{
            display: none;
        }
        li:last-child{
            margin-bottom: 12px;
        }
        li:hover{
            background-color: #000;
            a{
                color: #ffffff !important;
            }
            
        }
    }
    
}
.menu-asset:hover{
    background-color: #eaedf3;
    border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    .submenu{
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        font-family: "Adman_X_Li";
        position: absolute;
        background-color: #eaedf3;
        left: 0;
        width:100%;
        display: block;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        li{
            text-align: left;
            padding: 4px 20px;
            a{
                color: #818594;
                font-size: 18px;
            }
        }
        li::after{
            display: none;
        }
        li:last-child{
            margin-bottom: 12px;
        }
        li:hover{
            background-color: #000;
            a{
                color: #ffffff !important;
            }
            
        }
    }
    
}
.image-gallery-swipe {
    position: relative;
    max-width: 700px;
    margin: auto;
}
.image-gallery-thumbnails{
    .image-gallery-thumbnail{
       width: 140px;
    }
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    outline: none;
    border: 3px solid #a55056 !important;
}
.image-gallery-thumbnail:hover {
    outline: none;
    border: 3px solid #a55056 !important;
}
.image-gallery-left-nav {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAlCAYAAADFniADAAAACXBIWXMAAAsSAAALEgHS3X78AAAB8UlEQVRYhcWYgW3EIAxFP13gMkJGYIMyQkdghIzACBkhI2QEugEjMEI6AZVTiKI77hLAzVmyFOmC82RzHxsRQkCpCSEkABW9i8s/AXzH5wWAAzCHEFzxBwjqjAPoARgAnpYVuI/r+tPfOnzhLxOmEOSZU5yuCSqWpzQzR07xVBUUAM0IknNdBHUBUPIs2DuBnoLdA8mLgZLLV1DuTVAuCwVgeBNQ8iGxbIouhCAVvhWrL5/9hBDW0+EjAmkuIK01rLU1S2+RA6l0M0cJxnEMZNM01caYtz3VCtN1XZjneQXSWjfFSttJtQSRUgbnXFiWZX1myDjx1B+2SqkVhqD6vuf6F5pqqGEYtv1D5WMCqociEDJjDCdMPRSViUrmvefO0Ab1USom3nsopbZnKWWNJh1a1Z6iLFlr16y1ykCufE2tStpfJJxMUF+IA0FTIMoUGQkowz7rk6I39+EknEmzGkTU74+ZkSP19M9M6l4ZY9xDNZcwOZWPlL62dPdN3sQFVulTrvNky1ZLlnI9OtckXOrmaMSyFwPZM3Nfd+FU43J3C68uNf4bLAt0dJfQcfXuGZ9f3b6cuQqieXBhgln281011C5rBvVwy9m7qdNQd3DUVVD6jwDpd3qP3j8F8zAh15gQggSX/KEXDCHQIV9uAH4B7V2KJ1G7T8gAAAAASUVORK5CYII=)!important;
    left: 40px!important;
    width: 37px;
    height: 37px;
    padding: 0px!important;
    opacity: .4;
    svg{
        display: none;
    }
}
.image-gallery-right-nav {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAlCAYAAADFniADAAAACXBIWXMAAAsSAAALEgHS3X78AAAB8klEQVRYhc2YjY2DMAyFX7pAGYERssFlhBshIzACIzACIzBCbgNGYARugpweJRXiaMmPrz1LT0UqmK926thR3nukmlJKA/gEwM9qffwDwNd6PQNwlPd+TH4BoWIEoAbQApj4WIKm9bk6+l2nN9wi0SaCPBL9VEVQAExGZM5EfyYLCoAVhtnLJkG9ACjoEOydQA/B9kD6xUBB+hnU+Cao8RAKQPMmoKAmsNwrulKKVfiaXH3l7Nt7v+wOlxXI5gA552CtlaK6rhwIqRtyQt73vad1XSeVwuG+pkocWWsXsGEYfFVVxWBhOZlSR1prP8+zH8dxuS70Rx6Zzbau6wWKcMaYEl+tGBTF9IV11jTN/4AKatt2ASNgLlQvDcWITdO0pJJpTYW6rP2NmGmtMU03l8aY+3WqiaWP5YHRcc6VlIclfSKtCgtowTraigPJMhAUrR8WThojJfAD61DRs/pwFspQmwSKJjVtt5kux0mo4hn/sEfqtlBZKWTlltjvtqnbN3ni9SpR/VHnWbTgpaJ01KOLbzmRas9GLPdiIBcz91UvnGrGo7OFZ4cafw12CHR2llDl9u4RGp6dvsQcBXEenIVg5u18lw21iVqLfLg59mwqGmoHx66C4T8D5Pe8j/dHwfyakHNMKcWCS+2NG2tedwfgB/ftiyd+Y2QlAAAAAElFTkSuQmCC)!important;
    right: 40px!important;
    width: 37px;
    height: 37px;
    padding: 0px!important;
    opacity: .4;
    svg{
        display: none;
    }
}
.clearFilter{
    font-family: "Adman_X_Li";
    color: #818594;
    font-size: 20px;
    text-decoration: underline;
    cursor: pointer;
    line-height: 40px;
}
.image-gallery-left-nav:hover,.image-gallery-right-nav:hover  {
    opacity: 1;
}
.menu-asset:hover::after{
    display: none;
}
.favorite-title{
    font-family: "Adman_X_Li";
    color: #000;
    font-size: 22px;
    h2{
        color: #a55056;
    }
}
.saleprice{
    background-color: #a55056;
    color:#ffffff;
    border-radius: 6px;
    padding: 4px 12px;
    font-size: 26px;
}
.oldprice{
 
    font-size: 24px;
    text-align: center;
    position: relative;
}
.oldprice::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #a55056;
    top:49%;
    transform: rotate(-4deg);
}
.font-18{
    font-size: 18px;
}
.policy{
    font-family: "Adman_X_Li"; 
    text-align: left;
    font-size: 22px;
    
}
.text-warning{
    font-size: 16px;
    margin-top: 4px;
}
.flip-container {
	perspective: 1000px;
}
	/* flip the pane when hovered */
	.flip-container:hover .flipper, .flip-container.hover .flipper {
		transform: rotateY(180deg);
	}

.flip-container, .front, .back {
	width: 100%;
	height: 100%;
    cursor: pointer;
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;

	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
    position: relative;
}
.flip_title{
    color: #ffffff;
    position: absolute;
    width: 100%;
    padding: 16px;
    text-align: center;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    font-family: "Adman_X_Li";
    font-size: 24px;
}
/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
    background: #a55056;
    position: absolute;
}
.custom-loader {
    width:12px;
    height:12px;
    background: #a55056;
    border-radius: 50%;
    box-shadow: 20px 0 #a5505622,-20px 0 #a55056;
    animation:d5 1s infinite linear alternate;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  @keyframes d5 {
      0% {box-shadow: 20px 0 #a55056,-20px 0 #a5505622;background: #a55056}
      33%{box-shadow: 20px 0 #a55056,-20px 0 #a5505622;background: #a5505622}
      66%{box-shadow: 20px 0 #a5505622,-20px 0 #a55056;background: #a5505622}
  }

.overlay{
    width: 100%;
    height: 100vh;
    position: fixed;
    top:0;
    left:0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
}
.whitespace-logo{
    max-width: 240px;
    margin: auto;
    text-align: center;
}
#aboutus{
    position: absolute;
    bottom: 108px;
}
.notie_consent{
    background-color: #a55056;
    color: #ffffff;
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 16px;
    text-align: center;
    p{
        margin-bottom: 0;
    }
}
.btn-accept{
    border: none;
    border-radius: 12px;
    padding: 2px 16px;
}
.btn-accept:hover{
    color: #a55056;
}
.menu-line{
    width: 26px;
    height: 2px;
    background-color: #a55056;
    margin: 6px 0;
    display: block;
}
@media only screen and (max-width: 1600px) {
    .container-navbar{
        max-width: 100%;
        padding: 0px 16px;
        .logo-penguinasset {
            max-width: 180px;
        }
    }
    .container-navbar .navbar-nav li a.nav-link {
        font-family: "Adman_X_Li";
        color: #000000;
        font-size: 20px;
        padding: 0.1rem 0.5rem;
    }
    .navbar-expand-xl .navbar-collapse {
       
        flex-basis:100%;
    }
    .account a {
        font-family: "Adman_X_Li" !important;
        text-decoration: none;
        color: #000;
        margin-left: 6px !important;
        padding: 0.1rem 0rem !important;
        font-size: 20px !important;
    }
}
.h-followus{
    text-align: right;
    font-size: 18px;
    font-family: "Adman_X_Li" !important;
    color: #a65055;
    display: none;
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            display: inline-block;
            padding: 0px 8px;
            img{
                max-width: 20px;
            }
        }
        li:last-child{
            padding-right: 0;
        }
    }
}
@media only screen and (max-width: 1366px) {
    .container-navbar .navbar{
        max-width: 100%;
    }
    
}
@media only screen and (max-width: 1280px) {
    .navbar-light .navbar-toggler {
        right: unset !important;
        left: 0px;
        border:none !important
    }
    .navbar-toggler-icon{
        color: #a65055;
        
    }
    .container-navbar .navbar-nav li {
        padding: 0px 16px 0px;
        position: relative;
    }
    
}
@media only screen and (max-width: 1200px) {
    .navbar{
        display: inherit  !important;
    }
    .navbar-light .navbar-toggler {
        color: #a55056 !important;
        border-color: rgba(166, 80, 86, 0.5) !important;
        position: absolute;
        top: 8px;
        right: 0px;
    }
    .account {
        margin-left: auto;
        text-align: right;
        position: absolute;
        right: 0;
        top:6px;
        a{
            font-size: 18px !important;
        }
    }
    .account a:last-child{
        padding: 0rem 0.5rem !important;
    }
    .container-navbar .logo-penguinasset {
        max-width: 100px;
    }
    .navbar-light .navbar-brand{
        position: relative;
        left:calc(50% - 50px);
        
    }
}

@media only screen and (max-width: 1024px) {
    .logout {
        display: none !important;
    }
    .search-full{
        margin-top: 40px;
    }
    .f-menu{
        margin-top: 20px !important;
    }
    .filter-block{
        .col{
            width: 50%;
            flex: unset;
            margin-bottom: 12px;
        }
    }
    div.mrb-0{
        margin-bottom: 0px !important;
    }
    .contact-pannel{
        padding-left: 0!important;
        iframe{
            width: 100%;
        }
    }
    footer{
        .f-blog{
            text-align: left;
        }
    }
    .h-followus{
        display: block;
    }
}
@media only screen and (max-width: 767px) {
    .account{
        max-width: 110px;
        .profile{
            font-size: 18px!important;
            text-overflow: ellipsis; 
            width: 100%;
            overflow: hidden;
        }
        a{
            font-size: 18px!important;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            line-height: 22px; 
        }
    }
    .policy{
        font-size: 18px;
        line-height: 24px;
        h4{
            font-size: 20px;
            line-height: 24px;
        }
        h5{
            font-size: 18px;
            line-height: 24px;
        }
    }
    .black-logo{
        max-width: 240px;
    }
    p {
        font-family: "Adman_X_Li";
        font-size: 18px;
        line-height: 22px;
    }
    .contactus-form .row .col-12:first-child{
        padding-right: 12px;
    }
    .contact-pannel {
        padding-left: 12px !important;
    }
    .property-list .row:first-child{
        margin: 0px -6px;
    }
    .container-navbar .navbar {
        max-width: 100%;
        margin: 20px auto;
    }
    .search-block .search-full{
        padding-right: 0px;
    }
    .search-block .text-search {
        width: calc(100% - 100px);
    }
    .search-block .btn-search {
        position: absolute;
        right: 40px;
        top: 10px;
        max-width: 50px;
    }
    .penguin-9 {
        max-width: 60px;
        right: -18px;
        bottom: 0px;
        position: absolute;
    }
    .search-block{
        .col{
            max-width: 33.33%;
            width: 33.33%;
            flex: none;
            margin-bottom: 16px;
        }
    }
    .property-list{
        .asset-group-seleted{
            display: none !important;
        }
        .asset-group{
            width: 100%;
            a{
                margin:0px 12px ;
            }
        }
    }
    .contactus-form .row .col-12:last-child{
        padding: 16px !important;
    }
    .mail{
        position: relative;
        margin-bottom: 16px;
        a.c-facebook{
            position: relative;
            right: unset;
            top: unset;
            margin-right: 16px;
            img{
                position: inherit;
                top:unset;
            }
        }
        a.c-phone{
            position: relative;
            right: unset;
            top: unset;
            margin-right: 16px;
            img{
                position: inherit;
                top:unset;
            }
        }
        a.c-line{
            position: relative;
            right: unset;
            top: unset;
            margin-right: 16px;
            img{
                position: inherit;
                top:unset;
            }
        }
    }
    .price-range{
        width: 100%!important;
    }
    .filter-block{
        .col{
            width: 100%;
            flex: unset;
            margin-bottom: 12px;
        }
    }
    div.mrb-0{
        margin-bottom: 0px !important;
    }
    .account{
        margin-left: auto;
    }
    .container-navbar{
        max-width: 100%;
        padding: 0px 16px;
        margin: 10px auto;
    }
    .logo-penguinasset{
        max-width: 160px;
    }
    .container-navbar .navbar-nav li{
        padding: 0px 0px 0px;
        margin: 12px;
    }
    .container-navbar .navbar-nav li::after{
        display: none;
    }
    // .navbar-show{
    //     position: fixed !important;
    //     z-index:999999;
    //     background-color: #ffffff;
    //     width: 100%;
    //     top:0px;
    //     height: 100vh;
    //     left: 0;
    //     right: 0;
    //     padding: 0px 16px  !important;
    // }
    .col-seach{
        .col{
            flex: unset;
            text-align: center !important;
           .btn-penguin{
            margin-bottom: 16px;
           }
        }
    }
}
