.pg-conner{
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
.pg-conner .card-header {
    border-top-left-radius: 30px;
    color: #fff!important;
    background-color: #A65056!important;
}
.col.bank-interest{
    text-align: right;
    padding-right: 3em;
}
.dropdown-menu.show{
    overflow-y: auto;
}
.col-interest-box1{
    padding-left: 20px;
    padding-top: 10px;
}
.col-interest-box2{
    text-align: right;
    padding-right: 20px;
}
.p-ieterest-text1{
    padding-top: 3%;
}
.text-color01{
    color: #fff;
}
.text-color02{
    color: #969696;
}
.pd-top1{
    padding-top: 10px;
}
.pd-top2{
    padding-top: 25px;
}
.text-size-header{
    font-size: rem;
}
.text-size-header2{
    font-size: 3rem;
}
.text-size-data1{
    font-size: 2.5rem;
}
.text-pd-15px{
    padding-top: 15px;
}
.custom-btn{
    background-image: linear-gradient(to bottom, #000000, #161616, #242424, #343434, #444444);
    color: #9e9d9d;
}
.pg-input{
    border: 2px solid #d4d3d3;
    border-radius: 12px;
    color:#9e9d9d;
}
.pd-top2{
    padding-top: 15px;
}
.btn-size01{
    width: 200px;
    height: 40px;
}
.text-color02{
    color: #9b9b9b;
    padding-left: 20px;
}
.pd-bottom01{
    padding-bottom: 50px;
}