@font-face {
	font-family: 'Adman_X_Li';
	src: url('../font/DB AdmanRounded X v3.2.eot');
	src: local('☺'), url('../font/DB AdmanRounded X v3.2.woff') format('woff'), url('../font/DB AdmanRounded X v3.2.ttf') format('truetype'), url('../font/DB AdmanRounded X v3.2.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Adman_X_Bd';
	src: url('../font/DB AdmanRounded X Bd v3.2.eot');
	src: local('☺'), url('../font/DB AdmanRounded X Bd v3.2.woff') format('woff'), url('../font/DB AdmanRounded X Bd v3.2.ttf') format('truetype'), url('../font/DB AdmanRounded X Bd v3.2.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

